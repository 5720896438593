import { getToken } from "@/utils/token";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const token = getToken();

  return token ? <Navigate to={"/"} /> : <Outlet />;
};

export default ProtectedRoute;
